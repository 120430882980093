import React, { useRef, useState, useEffect } from 'react';
import { GeoJSON } from 'react-leaflet';
import { MapContainer, FeatureGroup } from 'react-leaflet';
import { TileLayer } from 'react-leaflet/TileLayer';
import bbox from 'geojson-bbox';
import AgMarker from '../images/ag_mark_small-2x.png'
import L from 'leaflet';
import { EditControl } from "react-leaflet-draw";
import geojsonArea from '@mapbox/geojson-area';

const icon = L.icon({ iconUrl: AgMarker, iconAnchor: [15, 35] });

function extractLayers(ref) {
  let layers = [];

  ref.current.eachLayer((layer) => {
    layers.push(layer.toGeoJSON());
  })

  return layers;
}

export default function ActivityPrescriptionMap({ recordIndex, initialGeoJson }) {
  const [fieldId, setFieldId] = useState(null)
  const [field, setField] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`/api/fields/${fieldId}`)
      const data = await response.json();
      return setField(data.field)
    }
    if (fieldId) fetchData()
  }, [fieldId])

  useEffect(() => {
    const toFieldId = `#job_plan_activities_attributes_${recordIndex}_field_id`
    $(toFieldId).on('change', function() { setFieldId(this.value ) });
    // Remove event listener
    return () => { 
      $(toFieldId).off('change');
    };
  }, []);

  const containerProps = {};
  try {
    const ext = bbox(JSON.parse(field?.geo_json));
    containerProps['bounds'] = [[ext[1], ext[0]], [ext[3], ext[2]]]
  } catch (exceptionVar) {
    containerProps['center'] = [41.73583039, -95.70333052];
    containerProps['zoom'] = 10;
  }

  function createMarker(feature, latlng) {
    return L.marker(latlng, { icon });
  }

  const fieldRef = useRef();
  const [features, xSetFeatures] = useState(initialGeoJson || [])
    
  // this will not trigger on the initial layer load, only for user based inputs.
  const onUpdate = () => {
    // map updates
    const newFeatures = extractLayers(fieldRef);
    xSetFeatures(newFeatures);
  };

  // add existing layers to the map
  const onMounted = () => {
    // this layer will never exist on the map, it is only used to parse the geojson layers.
    let leafletGeoJSON = new L.GeoJSON({ type: 'FeatureCollection', features });
    let leafletFG = fieldRef.current;

    // Clear all layers
    leafletFG.eachLayer(layer => {
      leafletFG.removeLayer(layer)
    });

    leafletGeoJSON.eachLayer(layer => {
      leafletFG.addLayer(layer)
    });
  };

  const fieldOutline = field?.geo_json ? JSON.parse(field?.geo_json)?.features.filter(f => f.geometry.type !== 'Point') : null
  let initialApplicationType;
  if (initialGeoJson?.length) {
    if (JSON.stringify(fieldOutline) !== JSON.stringify(initialGeoJson)) {
      initialApplicationType = 'partial_field'
    }
  }
  
  const [applicationType, setApplicationType] = useState(initialApplicationType);
  const [plannedAcres, setPlannedAcres] = useState();

  const onSetApplicationType = (v) => {
    setApplicationType(v)
    if (v == 'whole_field') {
      xSetFeatures(JSON.parse(field?.geo_json)?.features.filter(f => f.geometry.type !== 'Point'))
    } else {
      xSetFeatures([])
    }
  }

  useEffect(() => {
    const fieldSectionAcresList = features.map(f => (geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2));
    const sum = fieldSectionAcresList.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue), 0).toFixed(2);
    setPlannedAcres(sum)
  }, [JSON.stringify(features)])

  return (
    <div id={recordIndex}>
      <div className="control-group post_button optional job_plan_activities_acres_planned">
        <label className={`post_button optional control-label" for="job_plan_activities_attributes_${recordIndex}_acres_planned`}>
          Planned Acres
        </label>
        <div className="controls">
          <input 
            className="post_button optional span10"
            type="text"
            value={plannedAcres}
            name={`job_plan[activities_attributes][${recordIndex}][acres_planned]`}
            id={`job_plan_activities_attributes_${recordIndex}_acres_planned`}
            />
          <button
            onClick={(e) => {
              e.preventDefault()
              onSetApplicationType('whole_field')
            }}
            className="btn btn-info load-acres">Entire Field</button>
          <button 
            onClick={(e) => {
              e.preventDefault()
              onSetApplicationType('partial_field')
            }}
            className="btn btn-info load-acres">Partial Field</button>
        </div>
      </div>
      <input
        type="text"
        className="string optional span12"
        value={JSON.stringify({ features: features })}
        name={`job_plan[activities_attributes][${recordIndex}][app_geo_json]`}
        id={`job_plan_activities_attributes_${recordIndex}_app_geo_json`}
      />
      {
        field?.geo_json && applicationType === 'partial_field'
          ? (
            <div>
              <MapContainer
                scrollWheelZoom
                style={{ height: '420px' }}
                {...containerProps}
              >
                
              <TileLayer
                url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']} />
              <GeoJSON data={JSON.parse(field.geo_json)} pathOptions={{ color: '#6988E7' }} pointToLayer={createMarker} />
              <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'yellow' }} ref={fieldRef}>
                <EditControl
                  position='bottomleft'
                  draw={{
                    circlemarker: false, 
                    marker: false, 
                    circle: false, 
                    polyline: false, 
                    rectangle: false,
                    polygon: { shapeOptions: { color: 'yellow', opacity: 1 } }
                  }}
                  onCreated={onUpdate}
                  onDeleted={onUpdate}
                  onEdited={onUpdate}
                  onMounted={onMounted}
                />
              </FeatureGroup>
              {/* <Control prepend position='bottomleft'>
                <div style={{ borderRadius: 4, backgroundColor: 'rgba(51, 51, 51, 0.5' }}>
                  <button
                    style={{ backgroundColor: 'rgba(255, 255, 255)', border: 'none', margin: 2, borderRadius: 2, opacity: '1' }}
                    onClick={(e) => {
                      e.preventDefault()
                      xSetFeatures(JSON.parse(field?.geo_json)?.features.filter(f => f.geometry.type !== 'Point'))
                    }}
                  >
                  <div style={{display: "flex", flexWrap: "wrap", padding: '4px'}}>
                      Whole Field
                  </div>
                </button>
              </div>
              </Control> */}
            </MapContainer>
            </div>
          )
          : null
      }
    </div>
  );
}
