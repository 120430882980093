import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { FeatureGroup, GeoJSON, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import EditControl from './components/maps/EditControl';
import bbox from 'geojson-bbox';
import { uniqFeatures } from "./utilities";
import ActivityApplicationsTable from './components/maps/ActivityApplicationsTable';
import SearchableSelect from './components/SearchableSelect';
import geojsonArea from '@mapbox/geojson-area';

export default function FieldWorkerSubForm({
  initialGeoJson,
  fieldGeoJson,
  plannedGeoJson,
  index,
  activityId,
  processingAsAppliedData,
  asAppliedGeoJson: initialAsAppliedGeoJson,
  activityApplicationsCount,
}) {
  const extent = bbox(fieldGeoJson); 
  const [drawEnabled, setDrawEnabled] = useState(false)
  const [features, xSetFeatures] = useState(initialGeoJson || [])
  const [asAppliedGeoJson, setAsAppliedGeoJson] = useState(initialAsAppliedGeoJson)

  const setFeatures = (list) => {
    xSetFeatures(uniqFeatures(list))
  }

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const val = JSON.stringify({ type: 'Feature Collection', features })
    const fieldSectionAcresList = features.map(f => (geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2));
    const sum = fieldSectionAcresList.reduce((a, cv) => parseFloat(a) + parseFloat(cv), 0).toFixed(2);
    if (val) {
      const nn = `activity_sheet_activity_applications_attributes_${index}_actual_activities_attributes_0_acres_applied`
      document.getElementById(nn).value = sum
    }
  }, [JSON.stringify(features)]) 

  return(
    <>
      <SearchableSelect
        optionsUrl="/equipment/by_client?secondary=vehicle"
        label="Vehicle"
        inputId="activity_sheet_activity_applications_attributes_0_actual_activities_attributes_0_vehicle_id"
        inputName="activity_sheet[activity_applications_attributes][0][actual_activities_attributes][0][vehicle_id]"
      />
      <SearchableSelect
        optionsUrl="/equipment/by_client?secondary=implement"
        label="Implementation"
        inputId="activity_sheet_activity_applications_attributes_0_actual_activities_attributes_0_implement_id"
        inputName="activity_sheet[activity_applications_attributes][0][actual_activities_attributes][0][implement_id]"
      />
      <input
        type="text"
        className="string optional span12"
        value={JSON.stringify(features)}
        id="activity_sheet_activity_applications_attributes_0_actual_activities_attributes_0_geo_json"
        name="activity_sheet[activity_applications_attributes][0][actual_activities_attributes][0][geo_json]"
        readOnly
        // style={{display: 'none'}}
      />
      <MapContainer
        bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
        scrollWheelZoom
        style={{ height: '420px' }}
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Field Outline">
            <GeoJSON data={fieldGeoJson} pathOptions={{ color: '#6988E7', fillOpacity: 0 }}/>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Prescribed">
            <GeoJSON data={plannedGeoJson} pathOptions={{ color: 'yellow', fillOpacity: 0 }}/>
          </LayersControl.Overlay>
          {
            asAppliedGeoJson
              ? (
                <LayersControl.Overlay checked name="As Applied">
                  <GeoJSON data={asAppliedGeoJson} pathOptions={{ color: 'orange', weight: .3 }}/>
                </LayersControl.Overlay>
              )
              : null
          }

        </LayersControl>
        <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'orange' }}>
          <EditControl
            edit={{ edit: false, remove: false, }}
            position='bottomleft'
            features={features}
            skipFitBoundsOnChange
            setFeatures={setFeatures}
            draw={{ 
              circlemarker: false, 
              marker: false, 
              circle: false, 
              polyline: false,
              rectangle: drawEnabled ? { shapeOptions: { color: 'orange', opacity: 1 } } : null,
              polygon: drawEnabled ? { shapeOptions: { color: 'orange', opacity: 1 } } : null
            }}
          />
        </FeatureGroup>
      </MapContainer>
      <ActivityApplicationsTable 
        activityId={activityId}
        activityApplicationsCount={activityApplicationsCount}
        processingAsAppliedData={processingAsAppliedData}
        setDrawEnabled={setDrawEnabled}
        features={features}
        setFeatures={xSetFeatures}
        field={fieldGeoJson}
        asAppliedGeoJson={asAppliedGeoJson}
        setAsAppliedGeoJson={setAsAppliedGeoJson}
      />
    </>
  )
}
