import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import "leaflet/dist/leaflet.css";
import bbox from 'geojson-bbox';
import AgMarker from '../images/ag_mark_small-2x.png'
const icon = L.icon({ iconUrl: AgMarker, iconAnchor: [15, 35] });

export default function OverviewMap({ data: xData }) {
  const data = { ...xData, "features": xData.features.filter(f => !!f.geometry)}
  const extent = bbox(data); 
  
  function onEachFeature(feature, layer) {
    if (feature.properties && feature.properties.name) {
        layer.bindTooltip(feature.properties.operation + '<br/>' + feature.properties.name, {permanent: true, direction: 'center' });
    }
  }

  return (
    <MapContainer
      bounds={[[extent[1], extent[0]], [extent[3], extent[2]]]}
      scrollWheelZoom
      style={{ height: '600px' }}
    >
      <TileLayer
        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
        maxZoom= {20}
        subdomains={['mt1','mt2','mt3']}
      />
        <GeoJSON
          data={data}
          onEachFeature={onEachFeature}
          pointToLayer={(feature, latlng) => {
            return L.marker(latlng, {icon});
          }}
        />
    </MapContainer>
  );
}
