import { useEffect } from 'react';
const useLegalAddressFromShapefile = (filename, feature, setLoading, setLegalInputVal) => {
  useEffect(() => {
    if (filename && feature) {
      const { geometry } = feature
      const coordinates = geometry?.coordinates && geometry.coordinates[0] && geometry.coordinates[0][0];
      setLoading(true)

      async function fetchData() {
        const searchParams = new URLSearchParams({ coordinates })
        const response = await fetch("/api/legal_addresses/by_coordinates?" + searchParams);
        const data = await response.json();
        return (data)
      }
      // call the function
      fetchData()
        .then((res) => {
          const { legal_address } = res;
          setLegalInputVal({
            range: legal_address.range,
            range_direction: legal_address.range_direction,
            section: legal_address.section,
            state: legal_address.state,
            township: legal_address.township,
            township_direction: legal_address.township_direction
          })
        })
        // make sure to catch any error
        .catch(console.error);
    }

  }, [filename])
}

export default useLegalAddressFromShapefile;